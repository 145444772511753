<style scoped lang="less">
  .doc-home-page {
    background-color: #EFF1F7;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    .count {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      .num {
        color: #F4628F;
        font-size: 50px;
        font-weight: 400;
      }
    }
    .title {
      font-size: 16px;
      color: #3C3F54;
      margin-bottom: 15px;
    }
    .left {
      height: 100%;
      width: calc(100% - 420px);
      .top {
        padding: 20px;
        box-sizing: border-box;
      }
      .bottom {
        height: calc(100% - 230px);
        padding: 0 20px 20px 20px;
        position: relative;
      }
    }
    .right {
      width: 400px;
      height: calc(100% - 40px);
      padding: 16px;
      margin: 20px 20px 20px 0;
      background-color: #FFF;
      position: relative;
    }
  }
  .card {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    background-color: #FFF;
  }
  .list {
    width: 100%;
    height: calc(100% - 0px);
    overflow-y: auto;
  }
  .d-item {
    display: flex;
    padding: 20px 0;
    margin: 0 20px;
    background: #fff;
    cursor: pointer;
    border-bottom: 1px solid #F0F3F8;
    display: flex;
    align-items: center;
    .d-n {
      width: 100px;
      text-align: right;
    }
    .d-t {
      width: calc(100% - 100px);
    }
  }
  .d-item:hover {
    background: 0;
  }
  .r-title {
    font-size: 16px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 88px;
    overflow-y: auto;
    color: #3C3F54;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(203, 213, 224);
    padding-bottom: 15px;
  }
  .r-list {
    height: calc(100% - 130px);
    width: 100%;
    overflow-y:auto;
    .h-item {
      display: flex;
      padding: 8px 20px;
      justify-content: space-between;
    }
  }
  .wdb {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #999;
  }
  .btm-btn {
    height: 30px;
    position: absolute;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    color: #fff;
    background: #F4628F;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>

<template>
  <div class="doc-home-page">
    <div class="left">
      <div class="top">
        <div class="title">当前未办理公文分析</div>
        <div class="count card">
          <span class="num">{{dataList.length}}</span>
          <span class="dw" style="margin-bottom: -20px;padding-left: 5px;">条</span>
        </div>
      </div>
      <div class="bottom">
        <div class="list card">
          <div class="d-item" @click="chooseItem(item)" v-for="item in dataList" :key="item.id">
            <div class="d-t">
              {{item.title}}
            </div>
            <div class="d-n">
              {{item.dbUserLength ? (item.dbUserLength + '人待办') : '未安排处理人'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right card" v-if="chooseData">
      <div class="r-title">{{chooseData.title}}</div>
      <div class="r-list" v-if="chooseData.handles.length > 0">
        <div class="h-item" style="padding-bottom: 10px;">
          <div>人员</div>
          <div>待办环节</div>
        </div>
        <div class="h-item" v-for="(item, index) in chooseData.dbHandles" :key="index">
          <div>{{item.userName}}</div>
          <div>{{item.officialDocumentHandleStepType}}</div>
        </div>
      </div>
      <div v-else class="r-list wdb">
        无待办
      </div>
      <div class="btm-btn" @click="goDetail">
        查看详情
      </div>
    </div>
  </div>
</template>

<script>
import {
  officialDocumentRequest
} from '../../api'

export default {
  data () {
    return {
      dataList: [],
      chooseData: null
    }
  },
  activated () {
    this.loadData()
  },
  methods: {
    chooseItem (item) {
      this.chooseData = this.chooseData === item ? null : item
    },
    goDetail () {
      this.$router.push({
        name: 'hrms.doc.detail',
        query: {
          id: this.chooseData.id,
          type: this.chooseData.type,
          handle: this.chooseData.isHandle ? 1 : 0
        }
      })
    },
    async loadData () {
      let datas = []
      let parm = {
        status: 'doing'
      }
      if (this.$authFunsProxy.get || this.$authFunsProxy.getMy) {
        datas = await officialDocumentRequest.getDetail(parm)
      }
      datas.forEach(v => {
        v.dbNum = 0
        v.allNum = 0
        v.dbUserIds = []
        v.allUserIds = []
        v.handles = []
        v.nullSteps = false
        v.steps = v.steps || []
        v.steps.forEach(s => {
          s.handles = s.handles || []
          if (s.handles.length === 0) {
            v.nullSteps = true
          }
          v.allNum += s.handles.length
          v.dbNum += s.handles.filter(h => h.status === 'wait').length
          s.handles.forEach(h => {
            if (!v.allUserIds.includes(h.userId)) {
              v.allUserIds.push(h.userId)
            }
            if (h.status === 'wait' && !v.dbUserIds.includes(h.userId)) {
              v.dbUserIds.push(h.userId)
            }
          })
          v.handles = v.handles.concat(s.handles)
        })
        v.dbHandles = v.handles.filter(v1 => v1.status === 'wait')
        v.isHandle = v.allUserIds.includes(this.$store.getters.currentUserId)
        v.dbUserLength = v.dbUserIds.length
      })
      datas = datas.filter(v => v.dbUserIds.length > 0 || v.nullSteps)
      if (!this.$authFunsProxy.get && this.$authFunsProxy.getMy) {
        datas = datas.filter(v => v.isHandle)
      }
      this.dataList = datas
    }
  }
}
</script>
